<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <Button icon="arrow-left" @click="goBack"/>
                <h2>Jauns pasūtījums</h2>
            </div>
        </template>

        <template v-slot:content>
            <form class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg">

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div class="col-span-1 md:col-span-2">
                        <h2 class="text-xl font-bold dark:text-gray-300">Klients</h2>
                    </div>

                    <div class="col-span-1">
                        <Autocomplete placeholder="Klients" v-model="form.customer" :errors="errors.customer"
                                      url="/api/fetch_customers" :additionalFields="['address', 'reg_nr']"/>
                    </div>

                    <template v-if="form.customer">
                        <div class="col-span-1">
                            <div class="flex h-full items-end">
                                <div class="flex flex-wrap w-full">
                                    <ItemText title="Reģ. nr." :text="form.customer.reg_nr"/>
                                    <ItemText title="PVN. nr."
                                              :text="form.customer.vat_nr ? form.customer.vat_nr : 'Nav norādīts'"/>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-1">
                            <Autocomplete placeholder="Klienta filiāle" v-model="form.branch" :errors="errors.branch"
                                          :items="form.customer.branches" :additionalFields="['address']"
                                          :showItems="true"/>
                        </div>
                        <div class="col-span-1">
                            <Autocomplete placeholder="Valsts" v-model="form.country" :errors="errors.country"
                                        :items="countries" :showItems="true" :cleanable="false" />
                        </div>
                    </template>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div class="col-span-1 md:col-span-2">
                        <h2 class="text-xl font-bold dark:text-gray-300">Pasūtījuma datums</h2>
                    </div>

                    <div class="col-span-1">
                        <Input type="date" placeholder="Pasūtījuma datums" v-model="form.orderDate"
                               :errors="errors.orderDate"/>
                    </div>

                    <template v-if="mainCustomer">
                        <div class="col-span-1">
                            <Select v-model="form.managingBranch" :errors="errors.managingBranch"
                                    :items="mainCustomer.branches" placeholder="Apkalpojošā filiāle"/>
                        </div>
                    </template>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div class="col-span-1 md:col-span-2">
                        <Textarea placeholder="Piezīmes" v-model="form.notes" :errors="errors.notes"/>
                    </div>
                </div>

                <div class="col-span-2 pt-6 pb-3">
                    <Textarea placeholder="Iekšējās piezīmes" v-model="form.internal_notes"
                              :errors="errors.internal_notes"/>
                </div>

                <template v-if="form.customer">
                    <div
                        class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4 border-t border-gray-200 dark:border-gray-700">
                        <div class="col-span-1 md:col-span-2">
                            <h2 class="text-xl font-bold dark:text-gray-300">Preces</h2>
                        </div>

                        <div class="col-span-1 md:col-span-2">
                            <Checkbox text="Vairāki kodi" v-model="multipleCodes"/>
                        </div>

                        <template v-if="!multipleCodes">
                            <div class="col-span-1">
                                <Autocomplete placeholder="Meklēt preci no kataloga" url="/api/fetch_catalog_items"
                                              v-model="orderItemsModel"
                                              :params="{ customer_id: form.customer.id, is_for_sale: 1 }"
                                              :additionalFields="['sku']"
                                              @autocompleteItem="getCatalogItem"
                                              :closeOnClick="false"
                                              @autocompleteClose="orderItemsModel = null"
                                />
                            </div>
                        </template>

                        <template v-if="multipleCodes">
                            <div class="col-span-1 md:col-span-2">
                                <Input placeholder="Preču kodi" v-model="productCodes" @input="getProductCodes"/>
                            </div>

                            <template v-if="multipleCodesErrors">
                                <div class="col-span-1 md:col-span-2">
                                    <div
                                        class="w-full bg-gray-200 bg-opacity-70 dark:bg-gray-800 pl-4 pr-1 py-2 rounded-lg">
                                        <h3 class="text-xl font-semibold text-red-500 mb-3">
                                            {{ multipleCodesErrors.error_message }}</h3>
                                        <div class="w-full rounded-lg max-h-72 overflow-auto">
                                            <template v-for="(error, index) in multipleCodesErrors.product_codes"
                                                      :key="index">
                                                <p class="text-red-600 dark:text-red-200">{{ error }}</p>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>

                        <template v-for="(item, index) in orderItems" :key="item.custom_uuid">
                            <div class="col-span-1 md:col-span-2 border-b border-gray-200 dark:border-gray-700">
                                <SingleOrderItemForm :item="item" :index="index"
                                                     @remove-product="removeOrderItem(index)"
                                                     :customer="form.customer"/>
                            </div>
                        </template>

                    </div>
                </template>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-8" v-observe-visibility="visibilityChanged">


                    <div class="col-span-1 md:col-span-2">
                        <div class="flex flex-col justify-end">
                            <Checkbox class="md:mt-8" text="Reversais PVN" v-model="form.reverse_vat"/>
                            <div class="flex justify-end mb-2 dark:text-gray-300">
                                <span class="mr-3">Summa EUR: </span>
                                <p class="font-bold">{{ total.toFixed(2) }}</p>
                            </div>
                            <div class="flex justify-end mb-2 dark:text-gray-300">
                                <span class="mr-3">PVN EUR: </span>
                                <p class="font-bold">{{ totalVat.toFixed(2) }}</p>
                            </div>
                            <div class="flex justify-end mb-2 dark:text-gray-300">
                                <span class="mr-3">Kopā apmaksai EUR: </span>
                                <p class="font-bold">{{ totalWithVat.toFixed(2) }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="showSnackbar" class="bg-main4 p-5 fixed bottom-10 right-10 rounded-lg shadow-xl z-40">
                    <div class="flex justify-end">
                        <span class="mr-3 text-white">Summa EUR: </span>
                        <p class="font-bold text-white">{{ total.toFixed(2) }}</p>
                    </div>
                </div>

                <div class="pt-2">
                    <div class="flex justify-end">
                        <button @click="goBack" type="button"
                                class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                            Atcelt
                        </button>

                        <template v-if="form.customer && displaySubmitButton">
                            <template v-if="!loading">
                                <button type="button"
                                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                                        @click.prevent="submit">
                                    Pievienot
                                </button>
                            </template>
                            <template v-else>
                                <Loading/>
                            </template>
                        </template>
                    </div>
                </div>

            </form>
        </template>
    </Content>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {mapGetters} from "vuex";
import {debounce} from 'vue-debounce'
import axios from "axios"
import Calculations from "@/services/Calculations/calculations";
import Select from "@/components/Components/Select";
import Autocomplete from "@/components/Components/Autocomplete";
import Input from "@/components/Components/Input";
import Textarea from "@/components/Components/Textarea";
import ItemText from "@/components/Components/ItemText";
import Loading from "@/components/Components/Loading";
import Validation from "@/modules/Validation";
import Checkbox from "../../components/Components/Checkbox";
import SingleOrderItemForm from "@/components/Orders/OrderForms/SingleOrderItemForm"

const baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL

export default {
    name: "CreateOrder",
    components: {
        Select,
        Autocomplete,
        Input,
        Textarea,
        ItemText,
        Loading,
        Checkbox,
        SingleOrderItemForm,
    },
    data: () => ({
        multipleCodes: false,
        multipleCodesErrors: null,
        productCodes: null,

        form: {
            customer: null,
            branch: null,
            orderDate: new Date().toISOString().substr(0, 10),
            managingBranch: null,
            notes: '',
            reverse_vat: false,
            country: null,
        },
        orderItemsModel: null,
        vatRate: Calculations.defaultVatRate(),
        isLoadingCustomers: false,
        isLoadingCatalogItem: false,
        catalogItem: null,
        fetchedCustomers: [],
        fetchedCatalogItems: [],
        searchCustomer: null,
        searchCatalogItem: null,
        orderItems: [],
        showSnackbar: true,
        snackbar: true,
        timeout: -1,
        formValidation: {},
    }),
    computed: {
        ...mapGetters({
            mainCustomer: "systemMainCustomer",
            countries: "fetchedCountries",
            user: "user",
            loading: "loading",
            errors: "errors",
        }),
        total() {
            return Calculations.getItemSumByParamName(this.orderItems, 'totalWithDiscount');
        },
        totalVat() {
            return Calculations.getItemSumByParamName(this.orderItems, 'totalVat');
        },
        totalWithVat() {
            return this.total + this.totalVat;
        },
        displaySubmitButton() {
            return this.orderItems.length > 0 || this.form.notes
        },
    },
    created() {
        if (this.user) {
            this.form.managingBranch = this.user.customer.branch
        }
        if (!this.countries) {
            this.$store.dispatch('fetchCountries');
        }
    },
    watch: {
        searchCustomer() {
            this.searchCustomers()
            this.isLoadingCustomers = true
        },
        searchCatalogItem() {
            this.searchCatalogItems()
            this.isLoadingCatalogItem = true
        },
        catalogItem() {
            if (this.catalogItem) {
                this.addCatalogItemToOrderItems()
                this.catalogItem = null
                this.searchCatalogItem = null
                this.isLoadingCatalogItem = false
                this.fetchedCatalogItems = []
            }
        },
        'form.customer'() {
            if (!this.form.customer) {
                this.form.branch = null
                this.form.country = null
                this.orderItems = []
            }
            if (this.form.customer) {
                if (this.form.customer.is_export) {
                    this.vatRate = 0
                }
                if (this.form.customer.branches.length > 0) {
                    this.form.branch = this.form.customer.branches[0]
                }
                if (this.form.customer.country) {
                    this.form.country = this.form.customer.country
                } else {
                  this.form.country = null
                }
            }
        },
        user() {
            if (this.user) {
                this.form.managingBranch = this.user.customer.branch
            }
        },
        multipleCodes() {
            this.productCodes = null
        }
    },
    methods: {
        setOrderItemErrors() {
            this.formValidation = {}
            this.orderItems.map((item) => {
                this.formValidation[`price-${item.custom_uuid}`] = {rules: ['required', 'minVal0']};
                this.formValidation[`quantity-${item.custom_uuid}`] = {rules: ['required']}
                this.formValidation[`quantityFromStock-${item.custom_uuid}`] = {rules: ['required']}
            })
        },
        getOrderItemsValidationValues() {
            this.setOrderItemErrors()
            const form = {}
            this.orderItems.map((item) => {
                form[`price-${item.custom_uuid}`] = item.price
                form[`quantity-${item.custom_uuid}`] = item.quantity
                form[`quantityFromStock-${item.custom_uuid}`] = item.quantityFromStock
            })

            return form
        },

        getProductCodes() {
            this.multipleCodesErrors = null

            this.$store.dispatch("fetchMultipleCatalogItems", {
                products: this.productCodes,
            }).then(response => {
                this.addCatalogItemsToOrderItems(response.data.data)
                this.multipleCodes = false
            }).catch(error => {
                this.multipleCodesErrors = error.response.data
            })
        },
        addCatalogItemsToOrderItems(items) {
            items.forEach(item => {
                item.vatRate = item.category.reverse_vat ? 0 : this.vatRate
                item.quantity = 1
                item.quantityFromStock = 1
                item.totalDiscount = 0
                item.total = 0
                item.totalWithDiscount = 0
                item.totalVat = 0
                item.totalWithVat = 0
                item.branch = {}

                this.orderItems.push(item)
            })
        },

        getCatalogItem(value) {
            const val = {...value}
            val.custom_uuid = uuidv4()

            this.catalogItem = val
        },
        addCatalogItemToOrderItems() {
            this.catalogItem.vatRate = this.catalogItem.category.reverse_vat ? 0 : this.vatRate
            this.catalogItem.quantity = 1
            this.catalogItem.quantityFromStock = 1
            this.catalogItem.totalDiscount = 0
            this.catalogItem.total = 0
            this.catalogItem.totalWithDiscount = 0
            this.catalogItem.totalVat = 0
            this.catalogItem.totalWithVat = 0
            this.catalogItem.branch = {}

            this.orderItems.unshift(this.catalogItem)
        },

        removeOrderItem(index) {
            this.orderItems.splice(index, 1);
        },

        formatOrderItems() {
            return this.orderItems.map(orderItem => {
                let formatedOrderItem = {}
                formatedOrderItem['orderable_id'] = orderItem.id
                formatedOrderItem['orderable_type'] = 'App\\CatalogItem'
                formatedOrderItem['description'] = orderItem.name
                formatedOrderItem['sku'] = orderItem.sku
                formatedOrderItem['price'] = orderItem.price
                formatedOrderItem['quantity'] = orderItem.quantity
                formatedOrderItem['discount'] = orderItem.discount
                formatedOrderItem['vat_rate'] = orderItem.vatRate
                if (orderItem.branch.id) {
                    formatedOrderItem['branch_id'] = orderItem.branch.id
                }
                return formatedOrderItem
            })
        },
        searchCustomers: debounce(function () {
            if (this.searchCustomer) {
                axios.get(baseUrl + '/api/fetch_customers?q=' + this.searchCustomer)
                    .then(response => {
                        this.fetchedCustomers = response.data.data
                    })
                    .catch(error => {
                        this.errors.searchCustomer = error.response.data.message
                        this.searchCustomer = null
                    })
                    .finally(() => (this.isLoadingCustomers = false))
            } else this.isLoadingCustomers = false
        }, 700),
        searchCatalogItems: debounce(function () {
            if (this.searchCatalogItem) {
                this.fetchedCatalogItems = []

                axios.get(baseUrl + '/api/fetch_catalog_items', {
                    params: {
                        q: this.searchCatalogItem,
                        customer_id: this.form.customer.id
                    }
                })
                    .then(response => {
                        this.fetchedCatalogItems = response.data.data
                    })
                    .catch(error => {
                        this.searchCatalogItem = null
                    })
                    .finally(() => (this.isLoadingCatalogItem = false))
            } else this.isLoadingCatalogItem = false
        }, 700),
        goBack() {
            if (confirm("Vai esi drošs? Visi ievadītie lauki tiks pazaudēti")) {
                this.$router.go(-1);
            }
        },
        visibilityChanged(isVisible) {
            this.showSnackbar = !isVisible;
        },
        setData() {
            const data = {
                customer_id: this.form.customer.id,
                branch_id: this.form.branch.id,
                managing_branch_id: this.form.managingBranch.id,
                country_id: this.form.country ? this.form.country.id : null,
                order_date: this.form.orderDate,
                notes: this.form.notes,
                reverse_vat: this.form.reverse_vat,
                internal_notes: this.form.internal_notes,
                total: this.total,
                vat: this.totalVat,
                total_with_vat: this.totalWithVat,
            }
            if (this.orderItems.length > 0) {
                data['order_items'] = this.formatOrderItems()
            }
            return data
        },
        submit() {
            console.log(this.setData())
            this.$Progress.start()
            const form = this.getOrderItemsValidationValues()
            if (Validation(this.formValidation, form)) {
                this.$store.dispatch('createNewOrder', {
                    data: this.setData(),
                })
            } else this.$Progress.fail()
        }
    }
}
</script>