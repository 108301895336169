<template>
  <form v-if="form" class="space-y-6 w-full relative py-4 mb-2">

    <div class="absolute top-0 right-0">
      <Button icon="delete" @click="$emit('remove-product', item)" />
    </div>

    <div class="grid grid-cols-12 gap-6">

      <template v-if="form.image_url">
        <div class="col-span-12">
          <img :src="form.image_url" alt="BTE" class="max-w-[75px] max-h-[75px] w-full object-contain">
        </div>
      </template>

      <div class="col-span-12 sm:col-span-8">
        <Input name="name" placeholder="Preces nosaukums" v-model="form.name" />
      </div>

      <div class="col-span-12 sm:col-span-4 ">
        <Input name="sku" placeholder="Preces kods" v-model="form.sku" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
        <Input type="number" name="price" placeholder="Cena bez PVN" v-model="form.price" :errors="errors[`price-${form.custom_uuid}`]" @change="calculateTotals" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-1">
        <Input type="number" name="quantity" :placeholder="`Skaits (${form.measure_unit.short_name})`" v-model="form.quantity" :errors="errors[`quantity-${form.custom_uuid}`]" @change="changeQuantity" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-1">
        <Input type="number" name="discount" placeholder="Atlaide %" v-model="form.discount" :errors="errors.discount" @change="calculateTotals" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-1">
        <Input type="number" name="vatRate" placeholder="PVN %" v-model="form.vatRate" :errors="errors.vatRate" @change="calculateTotals" />
      </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <ItemText title="Summa(bez PVN) EUR" :text="form.totalWithDiscount" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <ItemText title="PVN EUR" :text="form.totalVat" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <ItemText title="Summa(ar PVN) EUR" :text="form.totalWithVat" />
        </div>


    </div>


  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Calculations from "../../../services/Calculations/calculations";
import Input from "@/components/Components/Input";
import ItemText from "@/components/Components/ItemText";

export default {
  name: "SingleOrderItemForm",
  components: {
    ItemText,
    Input,
  },
  props: ['item', 'customer'],
  data: () => ({
    total: 0,
    form: null,
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
    }),
  },
  mounted() {
    this.form = this.item
    this.calculateTotals()
  },
  methods: {
    changeQuantity() {
      this.calculateTotals()
    },
    calculateTotals() {
      const discountRate =  +this.form.discount / 100
      this.form.total = (this.form.price * this.form.quantity).toFixed(2)
      this.form.totalDiscount = (this.form.total * discountRate).toFixed(2)
      this.form.totalWithDiscount = (this.form.total - this.form.totalDiscount).toFixed(2)
      this.form.totalVat = (Calculations.getVat(this.form.totalWithDiscount, this.form.vatRate)).toFixed(2)
      this.form.totalWithVat = ((this.form.totalWithDiscount * 1)  + +this.form.totalVat).toFixed(2)
    },
  }
}
</script>

<style>
</style>